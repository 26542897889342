<template>
  <div class="flex flex-column" id="app">
    <Header/>
    <div class="main">
      <router-view/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import '@/assets/styles/global.scss'
import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
import vuescroll from 'vue-scroll'
import VueSocialSharing from 'vue-social-sharing'
import Header from './components/organisms/Header.vue'
import Footer from './components/organisms/Footer.vue'

Vue.use(vuescroll)
Vue.use(VueLazyload)
Vue.use(VueSocialSharing)

export default {
  name: 'app',
  components: {
    Header,
    Footer,
  },
}
</script>
