
import { Component, Vue, Watch } from 'vue-property-decorator'

import { localeFromPath } from '@/utils/helpers'

import SvgIcon from '@/components/atoms/SvgIcon.vue'
import ThemeMenuItems from '@/components/atoms/ThemeMenuItems.vue'
import MenuModal from '@/components/atoms/MenuModal.vue'

@Component({
  components: {
    SvgIcon,
    ThemeMenuItems,
    MenuModal,
  },
  data: () => ({
    showModal: false,
    showSearch: false,
  }),
  methods: {
    handleClick() {
      document.body.style.overflow = 'hidden'
    },
    handleCloseClick(e) {
      document.body.style.overflow = 'scroll'
    },
    handleSearchClick() {
      const elem = document.getElementById('searchForm')
      if (elem) {
        elem.style.display = 'block'
      }
    },
    handleSearchCloseClick(e) {
      const elem = document.getElementById('searchForm')
      if (elem) {
        elem.style.display = 'none'
      }
    },
  },
})

export default class Header extends Vue {
  constructor() {
    super()
    this.locale = ''
  }

  private locale: string

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.locale = localeFromPath(this.$route.path || '/')
  }
}
