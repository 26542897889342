module.exports = {
  enabledLanguages: ['en', 'es', 'fr', 'et', 'lt', 'lv', 'cs', 'sk', 'pl', 'hu', 'it', 'el'],
  url: 'https://exposingtheinvisible.org', // no trailing slash
  rssTitle: 'Exposing the invisible',
  rssDescription: `Through a series of films, interviews, guides, tools and workshops, 
    Exposing the Invisible brings together different techniques and individual practices 
    of those working at the new frontiers of investigation.`,
  rssFeedImage: 'https://cdn.ttc.io/i/fit/800/0/sm/0/plain/exposingtheinvisible.org/media20/tool/hero-tool-google-earth.jpg',

  // Content API settings
  contentApiUri: 'https://api.ttc.io/',
  contentApiNamespace: 'eti',

  media: {
    baseUrl: 'https://cdn.ttc.io/',
    namespace: 'exposingtheinvisible.org',
    sourcePath: 'i/fit/800/0/sm/0/plain/',
  },

  searchUrl: 'https://search.tacticaltech.org/results/?project=eti&',

  logoImage: '/eti-logo.jpg',
  heroImage: '/hero.jpg',
  scrollUpImage: '/scroll-up.png',
  kitBannerImage: '/kit-banner.jpg',

  // Path to file with an array of routes for prerendering static HTML
  prerenderRoutesFilePath: '_prerenderRoutes',

  // Path to file with array of routes for sitemap creation
  sitemapRoutesFilePath: '_sitemapRoutes',
  // Path to the sitemap
  sitemapFilePath: '_sitemap.xml',

  // Path to RSS feed
  rssFilePath: '_rss.xml',
}
