import _ from 'lodash'
import isUUID from 'is-uuid'
import Vue from 'vue'
import Vuex from 'vuex'

import { fetch, buildQueryUri } from '@/utils/data'
import {
  SET_STATUS, SET, ADD, REMOVE_BY_UUID, FILTER_CONTENT, RESET_FILTER_CONTENT,
} from '@/store/mutationTypes'

Vue.use(Vuex)

export const actions = {
  // Fetch from the Content API
  fetch({ commit }: {commit: any}, query: string) {
    if (_.isString(query)) {
      return new Promise((resolve, reject) => {
        fetch(buildQueryUri(query))
          .then((result: any) => {
            if (result && result.data) {
              if (_.isEmpty(result.data)) {
                commit('SET_STATUS', 404)
                reject(
                  new Error('404'),
                )
              }
            }
            commit('SET_STATUS', 200)
            resolve(result.data)
          })
          .catch((error: any) => {
            commit('SET_STATUS', 502)
            reject(error)
          })
      })
    }
    commit('SET_STATUS', 400)
    throw new Error('Query argument must be string')
  },

  // Set in the VUEX store
  set({ commit }: {commit: any}, payload: any) {
    if (payload) {
      if (!_.has(payload, 'key')) throw new Error('Payload argument is missing key attribute')
      if (!_.has(payload, 'data')) throw new Error('Payload argument is missing data attribute')
      if (_.isString(payload.key)) {
        commit('SET', { key: payload.key, data: payload.data })
      } else throw new Error('Key argument must be string')
    } else throw new Error('Payload argument is missing or undefined')
  },

  add({ commit }: {commit: any}, payload: any) {
    if (payload) {
      if (!_.has(payload, 'key')) throw new Error('Payload argument is missing key attribute')
      if (!_.has(payload, 'data')) throw new Error('Payload argument is missing data attribute')
      if (_.isString(payload.key)) commit('ADD', { key: payload.key, data: payload.data })
      else throw new Error('Key argument must be string')
    } else throw new Error('Payload argument is missing or undefined')
  },

  removeByUuid({ commit }: {commit: any}, payload: any) {
    if (payload) {
      if (!_.has(payload, 'key')) throw new Error('Payload argument is missing key attribute')
      if (!_.has(payload, 'uuid')) throw new Error('Payload argument is missing uuid attribute')
      if (_.isString(payload.key)) {
        if (isUUID.anyNonNil(payload.uuid)) {
          commit('REMOVE_BY_UUID', { key: payload.key, uuid: payload.uuid })
        } else throw new Error('Not a valid UUID')
      } else throw new Error('Key argument must be string')
    } else throw new Error('Payload argument is missing or undefined')
  },

  filterContent({ commit }: {commit: any}) {
    commit('FILTER_CONTENT')
  },

  resetFilterContent({ commit }: {commit: any}) {
    commit('RESET_FILTER_CONTENT')
  },

  setStatus({ commit }: {commit: any}, payload: string) {
    commit('SET_STATUS', payload)
  },
}

export const mutations = {
  [ADD](state: any, payload: any) {
    if (payload && payload.key && payload.data) {
      _.set(state, payload.key, _.concat(_.get(state, payload.key), payload.data))
    }
  },

  [REMOVE_BY_UUID](state: any, payload: any) {
    if (payload && payload.key && payload.uuid) {
      const copy = _.get(state, payload.key)
      _.remove(copy, (p: { uuid: any }) => p.uuid === payload.uuid)
      _.set(state, payload.key, copy)
    }
  },

  [SET](state: any, payload: any) {
    if (payload && payload.key && payload.data) {
      _.set(state, payload.key, payload.data)
    }
  },

  [SET_STATUS](state: any, payload: string) {
    state.status = payload
  },

  [FILTER_CONTENT](state: any) {
    const activeFilters = _.concat(
      _.map(state.active.theme.tags.active, (t: any) => t.uuid),
      _.map(state.active.theme.topics.active, (t: any) => t.uuid),
    )
    const filteredContent = _.compact(
      _.filter(state.active.theme.content.passive, (c: any) => {
        if (
          _.isEmpty(_.intersection(c.tags, activeFilters))
          && _.isEmpty(_.intersection(c.topics, activeFilters))
          && _.isEmpty(_.intersection(c['workshop-topics'], activeFilters))
        ) return undefined
        return c
      }),
    )
    _.set(state, 'active.theme.content.active', _.compact(filteredContent))
  },

  [RESET_FILTER_CONTENT](state: any) {
    _.set(state, 'active.theme.content.active', state.active.theme.content.passive)
  },
}

export const getters = {
  // Status of the API request (404, 502, etc)
  status: (state: any) => state.status,

  // Curated content for the active post
  curated: (state: any) => state.active.curated,

  activePost: (state: any) => state.active.post.post,

  activeTheme: (state: any) => state.active.theme.theme,

  // All content associated with the active Theme and which is the result of filtering
  activeContent: (state: any) => state.active.theme.content.active,

  // All content associated with the active Theme
  passiveContent: (state: any) => state.active.theme.content.passive,

  // contentType of active post
  activePostContentType: (state: any) => state.active.post.post.contentType,

  // All Tags from the API
  tags: (state: any) => state.content.tags,

  // All Tags from content associated with the active Theme which are set as an active filter
  activeTags: (state: any) => state.active.theme.tags.active,

  // All Tags from content associated with the active Theme but which are not set as a filter
  passiveTags: (state: any) => state.active.theme.tags.passive,

  // All Topics from the API
  topics: (state: any) => state.content.topics,

  // All News from the API
  news: (state: any) => state.content.news,

  // All Pages from the API
  pages: (state: any) => state.content.pages,

  // All Topics from content associated with the active Theme which are set as an active filter
  activeTopics: (state: any) => state.active.theme.topics.active,

  // All Topics from content associated with the active Theme but which are not set as a filter
  passiveTopics: (state: any) => state.active.theme.topics.passive,

  // All Tags found in the content for the active Theme
  tagsFromThemeContent: (state: any) => {
    let result:any = []
    _.forEach(state.active.theme.content.passive, (c: any) => {
      result = _.concat(
        result,
        _.filter(state.content.tags, (tag: any) => {
          if (_.includes(c.tags, tag.uuid)) return tag
          return undefined
        }),
      )
    })
    return _.uniqBy(_.compact(result), (r: any) => r.uuid)
  },

  // All Topics found in the content for the active Theme
  topicsFromThemeContent: (state: any) => {
    let result:any = []
    _.forEach(state.active.theme.content.passive, (c: any) => {
      result = _.concat(
        result,
        _.filter(state.content.topics, (topic: any) => {
          if (_.includes(c.topics, topic.uuid)) return topic
          return undefined
        }),
      )
    })
    return _.uniqBy(_.compact(result), (r: any) => r.uuid)
  },

  // All Topics found in the content for the active Post
  topicsFromActivePostContent: (state: any) => {
    let result:any = []
    _.forEach(state.active.post.post.topics, (c: any) => {
      result = _.concat(
        result,
        _.filter(state.content.topics, (topic: any) => {
          if (_.includes(c, topic.uuid)) return topic
          return undefined
        }),
      )
    })
    return _.uniqBy(_.compact(result), (r: any) => r.uuid)
  },

  // All Tags found in the content for the active Post
  tagsFromActivePostContent: (state: any) => {
    let result:any = []
    _.forEach(state.active.post.post.tags, (c: any) => {
      result = _.concat(
        result,
        _.filter(state.content.tags, (tag: any) => {
          if (_.includes(c, tag.uuid)) return tag
          return undefined
        }),
      )
    })
    return _.uniqBy(_.compact(result), (r: any) => r.uuid)
  },

  person: (state: any) => state.active.person,

  organisation: (state: any) => state.active.organisation,

  role: (state: any) => state.active.role,

  films: (state: any) => state.content.films,

  filmCollections: (state: any) => state.content.filmCollections,

  activeFilms: (state: any) => state.active.post.films,

  activeNews: (state: any) => state.active.post.news,

  activeFilmUuid: (state: any) => state.active.filmUuid,

  activeFilmCollectionUuid: (state: any) => state.active.filmCollectionUuid,
}

export default new Vuex.Store({
  state: {
    status: undefined, // Status of last API fetch expressed as HTTP status code (number)
    filtersDeactivated: false,
    active: {
      // post is the Article, Film, FilmCollection, Theme etc displayed on a given route
      post: {
        post: {}, // Currently displayed instance of a content type (object).
        themes: [], // All themes associated with this post
        topics: [], // All topics associated with this post
        tags: [], // All tags associated with this post
        persons: [], // All persons associated with this post
        organisations: [], // All persons associated with this post
        films: [], // All Films associated in case the active post is a Film Collection
      },
      curated: {}, // Currently displayed curated content (object)
      feature: {}, // Currently displayed feature (object)
      news: [], // Currently displayed news (object])
      theme: {
        theme: {}, // Active theme (object)
        content: {
          // Content associated with current theme and filtered by Topic and Tag (object[])
          active: [],
          passive: [], // Content associated with current theme (object[])
        },
        topics: {
          active: [], // Topics associated for this theme which are set as active filters
          passive: [], // Topics associated with this theme
        },
        tags: {
          active: [], // Tags associated for this theme which are set as active filters
          passive: [], // Tags associated with this theme
        },
      },
      landingPage: {},
    },
    // Content which has been fetched from the API but is not necessarily active
    content: {
      themes: [], // All themes, object[]
      tags: [], // All tags, object[]
      topics: [], // All topics, object[]
      persons: [], // All persons, object[]
      films: [], // All films, object[]
      filmCollections: [], // All filmCollections, object[],
      organisations: [], // All organisations, object[],
      news: [], // All news, object[],
      pages: [], // All pages, object[]
    },
  },
  actions,
  mutations,
  getters,
})
