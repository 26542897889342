/* eslint-disable no-return-assign */
import path from 'path'
import _ from 'lodash'
import validUrl from 'valid-url'
import axios from 'axios'

import conf from '../configuration'

export const fetch = uri => new Promise((resolve, reject) => {
  if (!validUrl.isUri(uri)) reject(new Error('Not a valid URI.'))
  axios.get(uri)
    .then(result => resolve(result))
    .catch(e => reject(e))
})

/* Build a Content API query
 *
 * @param {string} query - a query. Must start with a '?' if parameters are queried
 * @param {URL} apiURI - the URL of the Content API
 * @param {string} namespace - the namespace to query
 * @returns {URL} - Content API query
 */
export const buildQueryUri = (
  query,
  namespace = conf.contentApiNamespace,
  apiUrl = process.env.VUE_APP_ROOT_API ? process.env.VUE_APP_ROOT_API : conf.contentApiUri,
) => {
  let url = apiUrl
  if (validUrl.isUri(apiUrl)) {
    if (query && _.isString(query) && !_.isEmpty(query)
        && namespace
        && _.isString(namespace)
        && !_.isEmpty(namespace)
    ) {
      if (!_.endsWith(apiUrl, '/')) url += '/'
      return url + _.trim(path.join(namespace, query), '/')
    } throw (new Error('Not a valid query or namespace.'))
  } else throw (new Error('Not a valid URI.'))
}
