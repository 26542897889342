
export const FETCH_CONTENT = 'FETCH_CONTENT'

export const SET = 'SET'

export const SET_STATUS = 'SET_STATUS'

export const ADD = 'ADD'

export const FILTER_CONTENT = 'FILTER_CONTENT'

export const RESET_FILTER_CONTENT = 'RESET_FILTER_CONTENT'

export const REMOVE_BY_UUID = 'REMOVE_BY_UUID'
