
import { Vue, Component, Prop } from 'vue-property-decorator'
import { imagePath } from '@/utils/helpers'

@Component({
  methods: {
    imagePath,
  },
})
export default class ResponsiveImage extends Vue {
  @Prop() private src!: string

  @Prop() private alt!: string

  @Prop({ default: 1088 }) private canvasWidth!: number

  @Prop({ default: true }) private enableResponsive!: boolean

  @Prop({ default: 'w-100' }) private classes!: string

  @Prop({ default: 'loader' }) private loaderClasses!: string

  @Prop() private height!: number

  @Prop() private width!: number
}
