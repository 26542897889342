
import conf from '../configuration'
import * as localisationsStrings from '../localised-strings.json'

/* Returns localised string from an object with localisations.
 *
 * @param {string} key - the key holding the string in the translation object
 * @param {string} locale - the translation to get
 * @param {string} fallbackLocale - the fallback translation if the string is
 * not available in the requested locale
 * @param {object} localisations - the object with the translations.
 * Expected format is {locale: {key: stringToBeTranslated}}
 * @param {array} enabledLanguages - array of strings with ISO 639-1 language codes
 * @returns {string} - returns translated string
 */
export const l = (
  key,
  locale,
  fallbackLocale = 'en',
  localisations = localisationsStrings.default,
  enabledLanguages = conf.enabledLanguages,
) => {
  if (key && _.isString(key)) {
    if (
      localisations[locale]
      && localisations[locale][key]
      && localisations[locale][key] !== ''
      && enabledLanguages.includes(locale)) {
      return localisations[locale][key]
    }
    if (
      localisations[fallbackLocale]
      && localisations[fallbackLocale][key]
      && localisations[fallbackLocale][key] !== '') {
      return localisations[fallbackLocale][key]
    }
    return ''
  }
  throw (new Error('key must be a string'))
}

/* Returns locale part of the path. Works with 2 letter ISO 639-1 language formats in paths.
 *
 * @param {string} path - the path
 * @param {array} enabledLanguages - array of strings with ISO 639-1 language codes
 * @returns {string} - returns locale
 */
export const localeFromPath = (path, enabledLanguages = conf.enabledLanguages) => {
  if (path && _.isString(path)) {
    if (_.isArray(enabledLanguages) && !_.isEmpty(enabledLanguages)) {
      let localeCandidate = _.replace(_.split(path, '/')[1], '/', '')
      if (
        localeCandidate.length === 2
        && enabledLanguages.includes(localeCandidate)
      ) return localeCandidate
      if (localeCandidate.length === 3) {
        localeCandidate = _.replace(localeCandidate, '/', '')
        if (enabledLanguages.includes(localeCandidate)) return localeCandidate
        return 'en'
      }
      return 'en'
    } throw (new Error('enabledLanguages must be array and cannot be empty'))
  } throw (new Error('path must be a string and cannot be empty'))
}

/* Returns path without locale and without starting slash.
 * Works with 2 letter ISO 639-1 language formats in paths.
 *
 * @param {string} path - the path
 * @param {array} enabledLanguages - array of strings with ISO 639-1 language codes
 * @returns {string} - returns slug
 */
export const slugFromPath = (path, enabledLanguages = conf.enabledLanguages) => {
  if (path && _.isString(path)) {
    if (_.isArray(enabledLanguages) && !_.isEmpty(enabledLanguages)) {
      const pathElements = _.split(path, '/')
      if (path === '/') return undefined
      if (
        enabledLanguages.includes(pathElements[1])
      ) {
        const pathWithoutLocale = _.join(_.drop(pathElements, 2), '/')
        if (_.isEmpty(pathWithoutLocale)) return undefined
        if (!_.startsWith(pathWithoutLocale, '/')) {
          return `/${pathWithoutLocale}`
        } return pathWithoutLocale
      }
      if (_.isEmpty(path) || _.isUndefined(path)) {
        return undefined
      }
      return path
    } throw (new Error('enabledLanguages must be array and cannot be empty'))
  } throw (new Error('path must be a string and cannot be empty'))
}

export const truncateText = (text, length) => {
  if (text && _.isString(text)) {
    if (text.length > length) {
      return _.truncate(text, { length, separator: ' ' })
    }
    return text
  }
  return text
}

export const chunkateArray = (inputArray, chunkSize) => {
  if (inputArray.length) {
    return inputArray.map((e, i) => (
      i % chunkSize === 0 ? inputArray.slice(i, i + chunkSize) : null
    )).filter(e => e)
  }
  return undefined
}

/* Takes the source URI to an image on TTCs media server and runs it trough
 * img proxy to get a URI to a resized version of the same image.
 * The returned image URI has the format [mediaServer]/i/fit/[width]/0/sm/0/plain/
 *   [imageDirectory]/[image]
 *
 * @param {string} src - the image URI
 * @param {number} width - the width of the new image
 * @param {string} image directory - the image directory
 * @param {URI} mediaServer - the URL to the media server
 * @returns {URI} - returns the URI of the resized image
 */
export const imagePath = (
  imgSrc,
  width,
  imageDirectory = conf.media.namespace,
  mediaServer = conf.media.baseUrl,
) => {
  // eslint-disable-next-line no-param-reassign
  if (!mediaServer.endsWith('/')) mediaServer += '/'
  if (imgSrc && width) {
    // eslint-disable-next-line no-param-reassign
    if (!imgSrc.startsWith('/')) imgSrc = `/${imgSrc}`
    return [mediaServer, 'i/fit/', width.toString(), '/0/sm/0/plain/', imageDirectory, imgSrc].join('')
  }
  return ''
}

/* Get the Theme name from the URL
 * where the URL is expected to be in the format
 * [/locale]/theme/[content-type]
 * @returns {string} - returns the name of the Theme
 */
export const themeSegmentFromUrl = (pathName) => {
  const [firstSeg, secondSeg, thirdSeg, fourthSeg] = pathName.split('/')
  // Catch the locale in the path f.e. 'en'
  if (secondSeg.length === 2) {
    return thirdSeg
  }
  return secondSeg
}

/* Get the Content Type name from the URL
 * where the URL is expected to be in the format
 * [/locale]/theme/[content-type]
 * @returns {string} - returns the name of the Content Type
 */
export const contentTypeSegmentFromUrl = () => {
  const [firstSeg, secondSeg, thirdSeg, fourthSeg] = window.location.pathname.split('/')
  if (secondSeg.length === 2) {
    return fourthSeg
  }
  return thirdSeg
}

/* Return localised date string
*
* @param {date time string} date - Date Time String Format
* @param {locale} string - ISO 639-1 language code
* @param {options} - date formatting options as accepted by Date.prototype.toLocaleDateString
* with sensible defaults
* @returns {string} - formatted date or empty string if one of the arguments is not valid
*/
export const formatDate = (
  date,
  locale,
  options = {
    year: 'numeric', month: 'long', day: 'numeric',
  },
) => {
  if (_.isNaN(Date.parse(date))) return ''
  const d = new Date(date)
  if (_.isObject(options) && _.isString(locale)) {
    return d.toLocaleDateString(locale, options)
  } return ''
}

/* Order posts by date.
 * Uses the updatedDate field if it exists, otherwise falls
 * back to publicationDate field
 *
 * @param {array} postObjectsArray - array of post objects
 * @param {string} order - 'desc' or 'asc'
 * @returns {array} - returns ordered posts objects array
 * or the first argument if the posts format was not correct
 */
export const orderPostsByDate = (postObjectsArray, order) => {
  if (postObjectsArray
      && _.isArray(postObjectsArray)
      && (order === ('desc' || 'asc'))
  ) {
    const formatYYYYMMDDDateStringToArray = (date) => {
      if (date && _.isString(date)) {
        return _.map(
          _.split(date, '-'), d => parseInt(d, 10),
        )
      }
      return date
    }

    return _.orderBy(
      postObjectsArray, (post) => {
        if (post.updatedDate && post.updatedDate !== 'Invalid date') {
          /* Creating date from string in the format YYYY-MM-DD
           * Fallback to now date if this fails
           */
          const date = formatYYYYMMDDDateStringToArray(post.updatedDate)
          if (date && date[0] && date[1] && date[2]) {
            return new Date(
              // YYYY, M, DD
              // The month is 0 indexed, so we subtract 1
              date[0], date[1] - 1, date[2],
            )
          }
          return new Date()
        }
        const date = formatYYYYMMDDDateStringToArray(post.publicationDate)
        if (date && date[0] && date[1] && date[2]) {
          return new Date(
            date[0], date[1] - 1, date[2],
          )
        }
        return new Date()
      }, [order],
    )
  }
  return postObjectsArray
}
