

import { Component, Vue, Prop } from 'vue-property-decorator'


@Component({})
export default class SvgIcon extends Vue {
  @Prop({ default: null }) private iconName!: string

  @Prop({ default: null }) private width!: string

  @Prop({ default: null }) private height!: string

  get iconFile() {
    if (this.iconName) {
      return () => import(`@/assets/svg/${this.iconName}.svg`)
    }
    return null
  }
}
