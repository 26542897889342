import Vue from 'vue'
import VueRouter from 'vue-router'
import * as ls from '../localised-strings.json'

Vue.use(VueRouter)

export const routes = [
  {
    path: `/${ls.en.exploreSlug}`,
    redirect: `/${ls.en.readSlug}`,
  },
  {
    path: `/en/${ls.en.exploreSlug}`,
    redirect: `/en/${ls.en.readSlug}`,
  },
  {
    path: `/${ls.en.exploreSlug}/${ls.en.toolsSlug}`,
    redirect: `/${ls.en.readSlug}/${ls.en.toolsSlug}`,
  },
  {
    path: `/en/${ls.en.exploreSlug}/${ls.en.toolsSlug}`,
    redirect: `/en/${ls.en.readSlug}/${ls.en.toolsSlug}`,
  },
  {
    path: `/${ls.en.exploreSlug}/${ls.en.databasesSlug}`,
    redirect: `/${ls.en.readSlug}/${ls.en.databasesSlug}`,
  },
  {
    path: `/en/${ls.en.exploreSlug}/${ls.en.databasesSlug}`,
    redirect: `/en/${ls.en.readSlug}/${ls.en.databasesSlug}`,
  },
  {
    path: `/${ls.en.educateSlug}`,
    redirect: `/${ls.en.learnSlug}`,
  },
  {
    path: `/en/${ls.en.educateSlug}`,
    redirect: `/en/${ls.en.learnSlug}`,
  },
  {
    path: `/${ls.en.educateSlug}/${ls.en.guidesSlug}`,
    redirect: `/${ls.en.learnSlug}/${ls.en.guidesSlug}`,
  },
  {
    path: `/en/${ls.en.educateSlug}/${ls.en.guidesSlug}`,
    redirect: `/en/${ls.en.learnSlug}/${ls.en.guidesSlug}`,
  },
  {
    path: `/${ls.en.educateSlug}/${ls.en.workshopsSlug}`,
    redirect: `/${ls.en.learnSlug}/${ls.en.workshopsSlug}`,
  },
  {
    path: `/en/${ls.en.educateSlug}/${ls.en.workshopsSlug}`,
    redirect: `/en/${ls.en.learnSlug}/${ls.en.workshopsSlug}`,
  },
  {
    path: `/${ls.en.investigateSlug}`,
    redirect: `/${ls.en.watchSlug}`,
  },
  {
    path: `/en/${ls.en.investigateSlug}`,
    redirect: `/en/${ls.en.watchSlug}`,
  },
  {
    path: `/${ls.en.newsSlug}`,
    alias: [
      `/en/${ls.en.newsSlug}`, `/es/${ls.es.newsSlug}`, `/fr/${ls.fr.newsSlug}`,
    ],
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "news" */ '../components/templates/NewsOverviewPage.vue'),
  },
  { // Homepage
    path: '/',
    alias: [
      '/en', '/es', '/fr',

      // Films overview page
      `/${ls.en.filmsSlug}`,
      `/en/${ls.en.filmsSlug}`,
      `/es/${ls.es.filmsSlug}`,
      `/fr/${ls.fr.filmsSlug}`,

      // Podcast landing page
      '/en/podcasts',
      '/podcasts',
      '/en/eu-project-2021',
      '/eu-project-2021',

      // :slug catches the Databases and Tools routes
      // :Theme/:RelatedContentType
      `/${ls.en.readSlug}/${ls.en.databasesSlug}`,
      `/${ls.en.readSlug}/${ls.en.toolsSlug}`,
      `/${ls.en.readSlug}/${ls.en.articlesSlug}`,
      `/${ls.en.readSlug}/${ls.en.interviewsSlug}`,
      `/en/${ls.en.readSlug}/${ls.en.databasesSlug}`,
      `/en/${ls.en.readSlug}/${ls.en.toolsSlug}`,
      `/en/${ls.en.readSlug}/${ls.en.articlesSlug}`,
      `/en/${ls.en.readSlug}/${ls.en.interviewsSlug}`,
      // `/es/${ls.es.readSlug}/${ls.es.databasesSlug}`,
      // `/es/${ls.es.readSlug}/${ls.es.toolsSlug}`,
      // `/es/${ls.es.readSlug}/${ls.es.articlesSlug}`,
      // `/es/${ls.es.readSlug}/${ls.es.interviewsSlug}`,
      // `/fr/${ls.fr.readSlug}/${ls.fr.databasesSlug}`,
      // `/fr/${ls.fr.readSlug}/${ls.fr.toolsSlug}`,
      // `/fr/${ls.fr.readSlug}/${ls.fr.articlesSlug}`,
      // `/fr/${ls.fr.readSlug}/${ls.fr.interviewsSlug}`,

      `/${ls.en.watchSlug}/${ls.en.filmsSlug}`,
      `/${ls.en.watchSlug}/${ls.en.sketchesSlug}`,
      `/${ls.en.watchSlug}/${ls.en.talksSlug}`,
      `/${ls.en.watchSlug}/${ls.en.otherSlug}`,
      `/en/${ls.en.watchSlug}/${ls.en.filmsSlug}`,
      `/en/${ls.en.watchSlug}/${ls.en.sketchesSlug}`,
      `/en/${ls.en.watchSlug}/${ls.en.talksSlug}`,
      `/en/${ls.en.watchSlug}/${ls.en.otherSlug}`,
      // `/es/${ls.es.watchSlug}/${ls.es.filmsSlug}`,
      // `/es/${ls.es.watchSlug}/${ls.es.sketchesSlug}`,
      // `/es/${ls.es.watchSlug}/${ls.es.talksSlug}`,
      // `/es/${ls.es.watchSlug}/${ls.es.otherSlug}`,
      // `/fr/${ls.fr.watchSlug}/${ls.fr.filmsSlug}`,
      // `/fr/${ls.fr.watchSlug}/${ls.fr.sketchesSlug}`,
      // `/fr/${ls.fr.watchSlug}/${ls.fr.talksSlug}`,
      // `/fr/${ls.fr.watchSlug}/${ls.fr.otherSlug}`,

      `/${ls.en.listenSlug}/${ls.en.podcastsSlug}`,
      `/${ls.en.listenSlug}/${ls.en.audioSlug}`,
      `/en/${ls.en.listenSlug}/${ls.en.podcastsSlug}`,
      `/en/${ls.en.listenSlug}/${ls.en.audioSlug}`,
      // `/es/${ls.es.listenSlug}/${ls.es.podcastsSlug}`,
      // `/es/${ls.es.listenSlug}/${ls.es.audioSlug}`,
      // `/fr/${ls.fr.listenSlug}/${ls.fr.podcastsSlug}`,
      // `/fr/${ls.fr.listenSlug}/${ls.fr.audioSlug}`,

      `/${ls.en.learnSlug}/${ls.en.guidesSlug}`,
      `/${ls.en.learnSlug}/${ls.en.workshopsSlug}`,
      `/en/${ls.en.learnSlug}/${ls.en.guidesSlug}`,
      `/en/${ls.en.learnSlug}/${ls.en.workshopsSlug}`,
      // `/es/${ls.es.learnSlug}/${ls.es.guidesSlug}`,
      // `/es/${ls.es.learnSlug}/${ls.es.workshopsSlug}`,
      // `/fr/${ls.fr.learnSlug}/${ls.fr.guidesSlug}`,
      // `/fr/${ls.fr.learnSlug}/${ls.fr.workshopsSlug}`

    ],
    component: () => import(/* webpackChunkName: "landingpages" */ '../components/templates/LandingPage.vue'),
  },
  { // Podcasts
    path: '/podcasts/:slug',
    alias: [
      '/en/podcasts/:slug',
    ],
    component: () => import(/* webpackChunkName: "podcastpages" */ '../components/templates/Podcast.vue'),
  },
  {
    path: `/${ls.en.postsSlug}/:slug`,
    alias: [
      // English routes
      `/${ls.en.articlesSlug}/:slug`,
      `/${ls.en.pagesSlug}/:slug`,
      `/${ls.en.newsSlug}/:slug`,
      `/${ls.en.personsSlug}/:slug`,
      `/${ls.en.guidesSlug}/:slug`,
      `/${ls.en.guidesSlug}/:slug/:slug`,
      `/${ls.en.interviewsSlug}/:slug`,
      `/${ls.en.databasesSlug}/:slug`,
      `/${ls.en.toolsSlug}/:slug`,
      `/${ls.en.curriculaSlug}/:slug`,
      `/${ls.en.workshopsSlug}/:slug`,

      `/en/${ls.en.postsSlug}/:slug`,
      `/en/${ls.en.articlesSlug}/:slug`,
      `/en/${ls.en.pagesSlug}/:slug`,
      `/en/${ls.en.newsSlug}/:slug`,
      `/en/${ls.en.personsSlug}/:slug`,
      `/en/${ls.en.guidesSlug}/:slug`,
      `/en/${ls.en.guidesSlug}/:slug/:slug`,
      `/en/${ls.en.interviewsSlug}/:slug`,
      `/en/${ls.en.databasesSlug}/:slug`,
      `/en/${ls.en.toolsSlug}/:slug`,
      `/en/${ls.en.curriculaSlug}/:slug`,
      `/en/${ls.en.workshopsSlug}/:slug`,

      // Tranlation workshops
      `/es/${ls.es.workshopsSlug}/:slug`,
      `/fr/${ls.fr.workshopsSlug}/:slug`,
      `/et/${ls.et.workshopsSlug}/:slug`,
      `/lt/${ls.lt.workshopsSlug}/:slug`,
      `/lv/${ls.lv.workshopsSlug}/:slug`,
      `/cs/${ls.cs.workshopsSlug}/:slug`,
      `/sk/${ls.sk.workshopsSlug}/:slug`,
      `/pl/${ls.pl.workshopsSlug}/:slug`,
      `/hu/${ls.hu.workshopsSlug}/:slug`,
      `/it/${ls.it.workshopsSlug}/:slug`,
      `/el/${ls.el.workshopsSlug}/:slug`,
    ],
    component: () => import(/* webpackChunkName: "posts" */ '../components/templates/Post.vue'),
  },
  {
    // Film and Film Collection pages
    path: `/${ls.en.filmsSlug}/:slug`,
    alias: [
      `/${ls.en.filmCollectionsSlug}/:slug`,
      `/${ls.en.filmGroupsSlug}/:slug`, // legacy route for Film Group
      `/en/${ls.en.filmsSlug}/:slug`,
      `/en/${ls.en.filmCollectionsSlug}/:slug`,
      `/en/${ls.en.filmGroupslug}/:slug`, // legacy route for Film Group

      `/es/${ls.es.filmsSlug}/:slug`,
      `/es/${ls.es.filmCollectionsSlug}/:slug`,

      `/fr/${ls.fr.filmsSlug}/:slug`,
      `/fr/${ls.fr.filmCollectionsSlug}/:slug`,
    ],
    component: () => import(/* webpackChunkName: "films" */ '../components/templates/Film.vue'),
    meta: {
      scroll: false,
    },
  },
  {
    // Theme pages
    path: `/${ls.en.readSlug}`,
    alias: [
      `/${ls.en.learnSlug}`,
      `/${ls.en.watchSlug}`,
      `/${ls.en.listenSlug}`,

      `/en/${ls.en.readSlug}`,

      `/en/${ls.en.learnSlug}`,

      `/en/${ls.en.watchSlug}`,

      `/en/${ls.en.listenSlug}`,

    ],
    component: () => import(/* webpackChunkName: "themes" */ '../components/templates/Theme.vue'),
  },
  {
    // Error pages 404
    path: `/${ls.en.readSlug}/:slug`,
    alias: [

      `/${ls.en.learnSlug}/:slug`,
      `/${ls.en.watchSlug}/:slug`,
      `/${ls.en.listenSlug}/:slug`,

      `/en/${ls.en.readSlug}/:slug`,

      `/en/${ls.en.learnSlug}/:slug`,

      `/en/${ls.en.watchSlug}/:slug`,

      `/en/${ls.en.listenSlug}/:slug`,
    ],
    component: () => import(/* webpackChunkName: "FourOhFour" */ '../components/molecules/FourOhFour.vue'),
  },
  {
    // Pages
    path: '/:slug',
    alias: [
      '/en/:slug',
      '/es/:slug',
      '/et/:slug',
      '/fr/:slug',
      '/et/:slug',
      // Important legacy paths
      '/resources/:slug',
      '/en/resources/:slug',
      '/resources/:slug/:slug',
      '/en/resources/:slug/:slug',
    ],
    component: () => import(/* webpackChunkName: "pages" */ '../components/templates/Post.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta && to.meta.scroll === false
      && from.meta && from.meta.scroll === false) {
      return savedPosition
    }
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  },
})

export default router
