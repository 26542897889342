import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueLodash from 'vue-lodash'
import _ from 'lodash'
import App from './App.vue'
import router from './router'
import store from './store'

import injectInitialState from './utils/inject-initial-state'

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  render: h => h(App),
})

// https://markus.oberlehner.net/blog/how-to-pre-render-vue-powered-websites-with-webpack/

// During pre-rendering the initial state is
// injected into the global scope, here we
// fill the store with the initial state.
if ((window as any).__INITIAL_STATE__) store.replaceState((window as any).__INITIAL_STATE__)

router.beforeResolve(async (to, from, next) => {
  try {
    const components = router.getMatchedComponents(to)

    // By using `await` we make sure to wait
    // for the API request made by the
    // method to resolve before rendering the view.
    await Promise.all(components.map(x => (x as any).onUrlChange && (x as any).onUrlChange()))

    // The `injectInitialState()` function injects
    // the current state as a global variable
    // `__INITIAL_STATE__` if the page is currently
    // pre-rendered.
    if ((window as any).__PRERENDER_INJECTED) injectInitialState(store.state)
  } catch (error) {
    // This is the place for error handling in
    // case the API request fails for example.
    // console.log(error)
  }

  return next()
})

app.$mount('#app')

Vue.use(VueLodash, { lodash: _ })
Vue.use(VueMeta)
