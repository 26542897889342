
import {
  Component, Prop, Vue,
} from 'vue-property-decorator'
import _ from 'lodash'
import { mapState } from 'vuex'
import store from '@/store'
import { l, localeFromPath, chunkateArray } from '@/utils/helpers'

@Component({
  methods: {
    l,
    handleClick() {
      this.$emit('menuClick')
      document.body.style.overflow = 'scroll'
    },
  },
  computed: mapState({
    pages: (state: any) => (state.content && state.content.pages ? state.content.pages : undefined),
  }),
})
export default class MenuModal extends Vue {
  constructor() {
    super()
    this.locale = ''
  }

  private locale: string

  mounted() {
    this.locale = localeFromPath(this.$route.path || '/')
    if (_.isEmpty(this.$store.getters.pages)) {
      // Fetch Page content in the current language from the API
      store.dispatch(
        'fetch',
        // `/?contentType=page&&locale=${this.locale}`,
        '/?contentType=page',
      )
        .then((result: any) => {
          if (result) {
          // Set the fetched page content as pages in the VUEX store
            try {
              store.dispatch('set', { key: 'content.pages', data: result })
            } catch (err) {
            // console.log(err)
            }
          }
        })
        .catch(
          (error: string) => { throw new Error(error) },
        )
    }
  }

  chunkatePages(pages: any[], chunkSize = 3) {
    if (pages.length) {
      return chunkateArray(pages, chunkSize)
    }
    return undefined
  }
}
