

import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import _ from 'lodash'
import store from '@/store'
import { localeFromPath } from '@/utils/helpers'
import conf from '@/configuration'

import SvgIcon from '@/components/atoms/SvgIcon.vue'

@Component({
  components: {
    SvgIcon,
  },
  computed: mapState({
    themes: (state: any) => {
      if (state.content.themes) {
        return _.sortBy(
          _.filter(
            state.content.themes, (t: any) => t.displayInMenu,
          ),
          (t: any) => Number.parseInt(t.position, 10),
        )
      }
      return undefined
    },
  }),
  data: () => ({
    searchInput: '',
  }),
  methods: {
    handleFormSubmit(url:string) {
      window.open(url, '_blank')
    },
  },
})

export default class ThemeMenuItems extends Vue {
  private searchInput!: string

  mounted() {
    // Fetch themes in the current language from the API
    store.dispatch(
      'fetch',
      `/?contentType=theme&&locale=${localeFromPath(this.$route.params.slug || '/')}`,
    )
      .then((result: any) => {
        if (result) {
          // Set the fetched themes in the VUEX store
          try {
            store.dispatch('set', { key: 'content.themes', data: result })
          } catch (err) {
            // console.log(err)
          }
        }
      })
      .catch(
        // (error: string) => { throw new Error(error) },
      )
  }

  searchUrl(input: string) {
    return `${conf.searchUrl}term=${input}`
  }
}
