
import { Component, Vue, Watch } from 'vue-property-decorator'
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import ResponsiveImage from '@/components/atoms/ResponsiveImage.vue'
import { l, localeFromPath } from '@/utils/helpers'

@Component({
  components: {
    SvgIcon,
    ResponsiveImage,
  },
  methods: {
    l,
  },
})
export default class Footer extends Vue {
  constructor() {
    super()
    this.locale = ''
  }

  private locale: string

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    if (this.$route) {
      this.locale = localeFromPath(this.$route.path || '/')
    }
  }
}
